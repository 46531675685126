<template>
  <div>
    <load-profile></load-profile>
    <bet-setting-menu></bet-setting-menu>

    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      v-model="params"
      @search="search"
      @reset="reset"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #lotteryId="item">
          <b-form-group :label-for="item.field.key">
            <label>
              {{ $t(item.field.label) }}
            </label>
            <n-single-select
              :reduce="true"
              v-model="params.lotteryId"
              :selection-label="$i18n.locale == 'en' ? 'name' : 'nameKh'"
              :options="lotteries"
              :clearable="false"
            ></n-single-select>
          </b-form-group>
        </template>
        <template #lotteryTimeId="item">
          <b-form-group :label-for="item.field.key">
            <label>
              {{ $t(item.field.label) }}
            </label>
            <n-single-select
              :reduce="true"
              v-model="params.lotteryTimeId"
              :options="lotteryTimes"
              :clearable="false"
            ></n-single-select>
          </b-form-group>
        </template>
        <template #post="item">
          <b-form-group :label-for="item.field.key">
            <label>
              {{ $t(item.field.label) }}
            </label>
            <n-single-select
              :reduce="true"
              selection-key="post"
              selection-label="post"
              v-model="params.post"
              :options="channels"
              :clearable="false"
            ></n-single-select>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', resource)">
            <b-button
              variant="primary"
              :to="{ name: 'create-senior-bet-setting' }"
            >
              {{ $t("button.create") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #thead-top v-if="username">
            <b-tr class="text-center">
              <b-th colspan="9">
                <h4 class="text-white mb-0">{{ username }}</h4>
              </b-th>
            </b-tr>
          </template>
          <template #cell(lotteryTime)="data">
            {{
              moment(data.item.lotteryTime, "HH:mm")
                .locale($i18n.locale)
                .format("h:mm a")
            }}
          </template>
          <template #cell(maxWinAmount)="data">
            {{ data.item.maxWinAmount | currency(data.item.ccy) }}
          </template>
          <template #cell(digit)="data">
            {{ data.item.digit }} {{ $t("field.number") }}
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="$can('update', resource)"
                :to="{
                  name: 'update-senior-bet-setting',
                  params: { id: $route.params.id, betSettingId: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <n-button-delete
                v-if="$can('update', resource)"
                :loading="loading"
                @confirm="destroy(data.item)"
                variant="danger"
                size="sm"
                pill
              >
                <feather-icon icon="TrashIcon" />
              </n-button-delete>
            </div>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
          ref="pagination"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BModal,
  BRow,
  BTr,
  BTh,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import BetSettingMenu from "@/views/senior/BetSettingMenu.vue";
import NSingleSelect from "@/components/NSingleSelect";
import NButtonDelete from "@/components/NButtonDelete";
import moment from "moment";

const MemberBetSettingRepository = Repository.get("memberBetSetting");
const LotteryRepository = Repository.get("lottery");
const SeniorRepository = Repository.get("senior");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BForm,
    BFormGroup,
    BInputGroup,
    BBadge,
    BModal,
    BTr,
    BTh,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NSingleSelect,
    NButtonDelete,
    vSelect,
    BetSettingMenu,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    userData: function () {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.lotteryId"(value) {
      if (value) {
        this.params.lotteryTimeId = null;
        this.params.post = null;
        this.getLotteryTime(value);
      }
    },
    "params.lotteryTimeId"(value) {
      if (value) {
        this.params.post = null;
        this.getLotteryChannel(value);
      }
    },
  },
  data() {
    return {
      username: "",
      lotteries: [],
      lotteryTimes: [],
      channels: [],
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        lotteryId: Number(this.$route.query.lotteryId) || null,
        lotteryTimeId: Number(this.$route.query.lotteryTimeId) || null,
        post: this.$route.query.post || null,
      },
      moment,
    };
  },
  mounted() {
    this.show();
    this.getLottery();
    this.getData(this.params.page);
  },
  methods: {
    show() {
      SeniorRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.username = data.username;
        }
      });
    },
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.lotteries = [...data];
          if (this.params.lotteryId) {
            this.getLotteryTime(this.params.lotteryId);
          }
        }
      });
    },
    getLotteryTime(lottery) {
      const index = this.lotteries.findIndex((element) => {
        return element.id == lottery;
      });

      if (index != -1) {
        let times = this.lotteries[index].times;
        times = times.map((element) => {
          return {
            ...element,
            name: moment(element.name, "HH:mm")
              .locale(this.$i18n.locale)
              .format("h:mm a"),
          };
        });
        this.lotteryTimes = [...times];
        if (this.params.lotteryTimeId) {
          this.getLotteryChannel(this.params.lotteryTimeId);
        }
      }
    },
    getLotteryChannel(time) {
      const index = this.lotteryTimes.findIndex((element) => {
        return element.id == time;
      });

      if (index != -1) {
        this.channels = [...this.lotteryTimes[index].posts];
      }
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    search(searchText) {
      this.params.search = searchText;
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData(this.page);
    },
    getData() {
      this.loading = true;
      MemberBetSettingRepository.index({
        ...this.params,
        searchFields: this.searchFields,
        userId: this.$route.params.id,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(item) {
      this.loading = true;

      MemberBetSettingRepository.destroy({
        id: item.id,
      })
        .then((response) => {
          this.getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...TableFields];
    const searchFields = [...SearchInputs];
    const resource = "senior";

    return {
      fields,
      searchFields,
      resource,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
